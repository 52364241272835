import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import ContentBlock from "../components/ContentBlock"
import SubTitle from "../components/SubTitle"
import UsedTech from "../components/UsedTech"

import "../components/Layout.css"

import DesignCirclePartImage from "../images/mask@3x.png"
import DesignCornerImage from "../images/rectangle-md.svg"
import DesignRectangle from "../images/greener-square.svg"
import DesignDecorationRectangle from "../images/decoration-rectangle.svg"
import DesignDecorationEllipse from "../images/decoration-ellipse.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  aboutImage,
  responsiveImage,
  learningImage,
  aboutTitle,
  aboutDescription,
  responsiveTitle,
  responsiveDescription,
  learningTitle,
  learningDescription,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative md:flex container mx-auto py-12 px-10 lg:px-32">
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
          <p className="pb-8 md:pb-24">
            <TechTag>#edtech</TechTag>
            <TechTag>#elearning</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0 sm:w-3/4 items-center z-20">
          {listImage && (
            <img
              src={listImage.childImageSharp.fixed.src}
              alt=""
              className="mx-auto w-3/4"
            />
          )}
        </div>
      </div>

      <div
        style={{
          background: "url(" + aboutImage.childImageSharp.fixed.src + ")",
          backgroundSize: "cover",
        }}
      >
        <ContentBlock className="md:pb-64 md:pt-64">
          <div className="md:absolute md:right-40 md:bottom-0 md:w-106 bg-gray-900 p-10 pb-20 text-white">
            <SubTitle bulletPoint>{aboutTitle}</SubTitle>
            <p>{aboutDescription}</p>
          </div>
        </ContentBlock>
      </div>

      <ContentBlock className="md:flex md:flex-row">
        <div className="md:w-1/2 md:pr-20 relative md:flex items-center">
          <div>
            <SubTitle bulletPoint>{responsiveTitle}</SubTitle>
            <p className="mb-16">{responsiveDescription}</p>
          </div>
        </div>
        <div className="mt-10 md:mt-0 md:w-1/2 flex justify-center">
          <img
            src={responsiveImage.childImageSharp.fixed.src}
            alt=""
            className=""
          />
        </div>
      </ContentBlock>

      <div className="bg-gray-300">
        <img
          src={DesignDecorationEllipse}
          alt=""
          className="hidden md:block absolute w-80 opacity-50"
        />
        <ContentBlock className="md:py-0">
          <div className="md:flex md:flex-row">
            <img
              src={DesignCornerImage}
              alt=""
              className="hidden md:block absolute w-20 opacity-75 left-20 -top-12"
            />
            <div className="md:w-1/2 md:px-20 md:pl-0">
              <img
                src={learningImage.childImageSharp.fixed.src}
                alt=""
                className="md:relative bottom-0 right-0"
              />
            </div>
            <div className="md:w-1/2 md:flex items-center">
              <div className="mt-8 md:mt-0">
                <SubTitle bulletPoint>{learningTitle}</SubTitle>
                <p>{learningDescription}</p>
              </div>
              <img
                src={DesignDecorationRectangle}
                alt=""
                className="hidden md:block absolute w-80 opacity-50 right-40 -bottom-12"
              />
            </div>
          </div>
        </ContentBlock>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <UsedTech title={title} AppDev SoftDev />
    </div>
  )
}

const Soma = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default Soma

export const pageQuery = graphql`
  query SomaID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        aboutTitle
        aboutDescription
        responsiveTitle
        responsiveDescription
        learningTitle
        learningDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        aboutImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        responsiveImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        learningImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
